import React from "react";
import Helmet from "react-helmet";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image"

import Layout from '../../layouts'

const PageBlog = ({ data }) => (
  <Layout>
    <Helmet>
      <title>Blog - Sureventure Camperbouw</title>
      <meta name="description" content="Blog van Surventure Camperbouw" />
    </Helmet>
    <section className="panel content-text">
      <h1>Blog</h1>
      {/*<p className="intro">Blog intro.</p>*/}
    </section>

    <section id="lijst" className="panel panel--xlarge portfolio-list">
      {data.articles.edges && data.articles.edges.map(function({ node }, index){
        const article = node;
        return (
          <article key={index} className="portfolio-list__item">
              <div className={index%2 === 0 ? 'row order-even block v-align-top' : 'row order-odd block v-align-top'}>
                  <div className="small-12 medium-6 large-8 columns content-img">
                    <Link to={`/blog/${article.slug.current}`}>
                      {article.video &&
                        <img className='video-play-button' src="/images/icons/play.svg" alt="Play"/>
                      }
                      <Img fluid={article.image.asset.fluid} alt={article.image.title} />
                    </Link>
                  </div>
                  <div className="small-12 medium-6 large-4 columns content-text">
                    <h2 className="portfolio-list__item__title heading-beta"><Link to={`/blog/${article.slug.current}`}>{article.title}</Link></h2>
                    {article.description &&
                      <div className="portfolio-list__item__description">{article.description}</div>
                    }
                    <p className="portfolio-list__item__description">{}</p>
                    <Link to={`/blog/${article.slug.current}`}>Lees dit artikel</Link>
                  </div>
              </div>
          </article>
        );
      })}
    </section>
  </Layout>
)

export default PageBlog

export const query = graphql`
  query {
    articles: allSanityBlog(limit: 1000, sort: {fields: publicationDate, order: DESC}) {
      edges {
        node {
          id
          slug {
            current
          }
          title
          description
          video
          image {
            asset {
              fluid(maxWidth: 1000) {
                aspectRatio
                sizes
                src
                srcSet
                srcWebp
                srcSetWebp
              }
            }
          }
        }
      }
    }
  }
`
